module Main exposing (..)

import Array exposing (map)
import Browser
import Browser.Navigation as Nav
import Output
import Platform.Cmd as Cmd
import Questions exposing (questions)
import Serde exposing (jsonDecodeResponse)
import Types exposing (..)
import Url exposing (..)
import Util exposing (fetchData, httpErrortoString, postSubmission, postTelemetry, routeParser, updateSelection)


main : Program () Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = always NoOp
        , onUrlRequest = \u -> ClickedLink u
        , view = view
        , update = update
        , subscriptions = always Sub.none
        }


init : flags -> Url -> Nav.Key -> ( Model, Cmd Msg )
init _ url _ =
    case routeParser url of
        -- Are we opening a plan?
        Just (RoutePlan e id) ->
            ( LoadingPlan, fetchData e id )

        -- Otherwise default to new Questionnaire
        _ ->
            let
                defaultQAs =
                    questions |> map (\q -> { question = q, answer = Nothing })
            in
            ( CapturingQuestionnaireAnswers defaultQAs, Cmd.none )



-- ( ProcessingSubmission, Cmd.none )


view : Model -> Browser.Document Msg
view model =
    { title = "Strategic plan: disruption leverage"
    , body =
        case model of
            LoadingPlan ->
                Output.loadingplan

            CapturingQuestionnaireAnswers answers ->
                Output.questionnaire_show_questions answers

            CapturingEmail _ email ->
                Output.questionnaire_capture_email email

            ProcessingSubmission ->
                Output.questionnaire_processing

            DisplayingEndMessage ->
                Output.questionnaire_final_message

            DisplayingPlanNotFound ->
                Output.plan_notfound

            DisplayingReceivedPlan response ->
                Output.plan_view response

            DisplayingError error ->
                Output.display_error error
    }


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model ) of
        ( ClickedLink (Browser.Internal _), _ ) ->
            ( model, Cmd.none )

        ( ClickedLink (Browser.External url), _ ) ->
            ( model, Nav.load url )

        ( RecordSelection question_index selection_index, CapturingQuestionnaireAnswers captured_questions ) ->
            case updateSelection captured_questions question_index selection_index of
                Err error ->
                    ( DisplayingError error, Cmd.none )

                Ok val ->
                    ( CapturingQuestionnaireAnswers val, postTelemetry val )

        ( RecordEmail new_email, CapturingEmail captured_questions _ ) ->
            ( CapturingEmail captured_questions new_email, Cmd.none )

        ( SubmitAnswers, CapturingQuestionnaireAnswers captured_questions ) ->
            ( CapturingEmail captured_questions "", Cmd.none )

        ( AcknowledgeTelemetrySent, _ ) ->
            ( model, Cmd.none )

        ( SubmitEmail, CapturingEmail qas email ) ->
            ( ProcessingSubmission, postSubmission qas email )

        ( AcknowledgeSubmission, _ ) ->
            ( DisplayingEndMessage, Cmd.none )

        ( ReceivedPlan (Err e), LoadingPlan ) ->
            ( DisplayingError (FailedtoLoadPlan (httpErrortoString e)), Cmd.none )

        ( ReceivedPlan (Ok v), LoadingPlan ) ->
            case jsonDecodeResponse v of
                Err e ->
                    ( DisplayingError e, Cmd.none )

                Ok decoded_plan ->
                    ( DisplayingReceivedPlan decoded_plan, Cmd.none )

        ( _, _ ) ->
            ( DisplayingError (InvalidModelMessage model msg), Cmd.none )
